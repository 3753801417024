import React from 'react';
import Drawer from 'react-drag-drawer';
import './index.css';
import Undraw from 'react-undraw';
import { Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card } from 'react-bootstrap';
import {
   MdVpnKey,
   MdVisibility,
   MdVisibilityOff


} from 'react-icons/md';

export default class DialogBoxs extends React.Component {


    showOkBtnInProcessing() {
        if (this.props.noOfcompletedData == this.props.noOfDataGoingToProcess) {
            return (
                <Button
                    style={{ backgroundColor: this.props.unProcessedData.length ? "red" : "green", borderColor: "green", marginRight: 15 }}
                    className="mt-3 pull-right"
                    onClick={() => {

                        this.props.hindCSVOperationDialog();

                    }}
                >{this.props.unProcessedData.length ? "Show Unprocess STB" : "OK"}</Button>
            );
        } else {
            return null;
        }
    }
    render() {
        return (
            <div>




                <Drawer

                    open={this.props.isShowSuccessDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold" }}> Success</div>

                            <Undraw
                                primaryColor="green"
                                style={{ marginTop: 10, display: 'block', width: 300, marginBottom: 10, height: 150, marginLeft: 79 }}
                                name="completed" />


                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindSuccessDialog();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer

                    open={this.props.isShowErrorDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold" }}>Unable to Process !</div>
                            <div style={{ fontSize: 14, fontWeight: "bold", color: "red", marginTop: 10 }}>{this.props.errorMessage}</div>

                            <Undraw
                                primaryColor="red"
                                style={{ marginTop: 10, display: 'block', width: 500, marginBottom: 10, height: 250, marginLeft: 79 }}
                                name="cancel" />


                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindErrorDialog();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>
                <Drawer

                    open={this.props.isShowPasswordDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <center>
                            
                                <div style={{ fontSize: 18, fontWeight: "bold" }}>Password</div>
                                <MdVpnKey size={50} color='#808080' style={{marginTop:5}}/>
                            </center>

                          

                            <input
                                onChange={e => {

                                    this.props.changeUserPassword(e.target.value)
                                }}
                                className="form-control"
                                id="DomainName"
                               // type="name"
                                placeholder=" Password"
                                value={this.props.userPassword}
                                maxLength={16}
                                minLength={8}
                                type={this.props.showPassword ? "text":"password"}
                                style={{ width: 350, alignItems: "center", textAlign: "center", marginTop: 10 }}
                            />
                            <center>
                          { this.props.showPassword ?<MdVisibility 
                          onClick={()=>{
                            this.props.updateShowPassword(!this.props.showPassword)
                          }}
                         
                          size={20} color="red"/> :  <MdVisibilityOff
                          onClick={()=>{
                            this.props.updateShowPassword(!this.props.showPassword)
                          }}
                          size={20} color='#000'/>} 
                            </center>
                           
                            <center>
                            <label style={{ width: 350,fontSize:11,color:"#808080"}}>For new users, a password will be created automatically; for existing users, please enter the right password.</label>
                            <br></br>
                            <label style={{fontSize:13,color:"red"}}>{this.props.passwordErrorMessage}</label>
                            </center>
                            <div style={{ width: 350}}>
                            <label style={{fontSize:13,color:"#000",marginTop:10}}>Password criteria :</label><br></br>
                            <label style={{fontSize:13,color:"#A9A9A9"}}>. Minimum : 8 and Maximum : 16</label><br></br>
                            <label style={{fontSize:13,color:"#A9A9A9"}}>. Must include a lower and upper case latters.</label><br></br>
                            <label style={{fontSize:13,color:"#A9A9A9"}}>. Must include a minimum of one number.</label><br></br>
                            <label style={{fontSize:13,color:"#A9A9A9"}}>. Must include a anyone of the sysmbol !@=%&*</label><br></br>
                            </div>

                            
                            <center>
                                <Button
                                    style={{ backgroundColor: "red", borderColor: "red", marginRight: 15 }}
                                    className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.hindPasswordDialog();

                                    }}
                                >Cancel</Button>
                                <Button
                                    style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                    className="mt-3 pull-right"
                                    onClick={() => {

                                        this.props.submitPassword();

                                    }}
                                >Submit</Button>

                            </center>

                        </Card.Body>
                    </Card>

                </Drawer>









            </div>
        );
    }
}